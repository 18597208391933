#overlay{
    /*　画面全体を覆う設定　*/
      position:fixed;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color:rgba(0,0,0,0.5);
    
      /*　画面の中央に要素を表示させる設定　*/
      display: flex;
      align-items: center;
      justify-content: center;
}
#content{
    z-index:2;
    width:50%;
    padding: 1em;
    background:#fff;
  }
  .required::after {
    content: " *";
    color: red;
    vertical-align: middle;
  }

  /* Input Box Class */
  .input-box-shadow{
    box-shadow: inset 1px 1px rgb(155,158,162)
  }
  .flex{
    display: flex;
  }
  .justify-content-end{
    justify-content: end;
  }

 /* toggle button style start*/
.toggle-slider {
    width: 200px;
    height: 40px;
    background-color: #ccc;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: 0.15s ease-out;
}

.slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background-color: #57bf42;
    border-radius: 20px;
    position: absolute;
    color: white;
    transition: left 0.15s ease-out; /* Add a transition for smooth sliding */
}

.slider.left {
    left: 0;
}

.slider.right {
    right: 0;
}

.values {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 5px;
}

.value {
    border: 2px solid #ccc;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

.value.active {
    background-color: #2196F3;
    color: #fff;
    border: 2px solid #2196F3;
}

.switch {
    position: relative;
}

.switch label {
    width: 55px;
    height: 23px;
    background-color: #999;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
}

.switch input[type="checkbox"] {
    visibility: hidden;
}

.switch label:after {
    content: '';
    width: 21px;
    height: 21px;
    border-radius: 50px;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: 100ms;
    background-color: white;
}

.switch .look:checked + label:after {
    left: 32px;
}

.switch .look:checked + label {
    background-color: #57bf42;
}
/*toggle button style ends*/

.custom-navbar-brand{
  margin: 0px 16px 0px 0px;
  cursor: pointer;
}

  /* LOADING ANIMATION START */
  /* Center the loading container both horizontally and vertically */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: rgba(0,0,0,0.3);
}

.loading-animation {
  width: 2.5em;
  height: 3em;
  border: 3px solid transparent;
  border-top-color: #fc2f70;
  border-bottom-color: #fc2f70;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }
  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}

  /* TABLE LOADING ANIMATION */
  .table-row-loading {
    display: table-row;
  }
  
  .table-row-loading td {
    text-align: center;
    padding: 10px;
  }
  
  /*.loading-container {
    display: flex;
    justify-content: center; !* Center horizontally *!
    align-items: center; !* Center vertically *!
    height: 100%;
  }
  
  .loading-animation {
    width: 2.5em;
    height: 3em;
    border: 3px solid transparent;
    border-top-color: #fc2f70;
    border-bottom-color: #fc2f70;
    border-radius: 50%;
    animation: spin-stretch 2s ease infinite;
  }
  
  @keyframes spin-stretch {
    50% {
      transform: rotate(360deg) scale(0.4, 0.33);
      border-width: 8px;
    }
    100% {
      transform: rotate(720deg) scale(1, 1);
      border-width: 3px;
    }
  }*/
  
  
 /* LOADING ANIMATION END */
  