body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .image-preview-item{
  margin-bottom: 16px;
  border: 16px solid #F1F0EF;
  border-radius: 10px;
}

.image-preview-image{
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: contain;
} */

.image-preview-item {
  position: relative;
  margin-bottom: 16px;
  border: 16px solid #F1F0EF;
  border-radius: 10px;
}

.image-preview-image {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: contain;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.close-icon-text {
  font-size: 20px;
  color: #000; /* Set the color of the close icon */
}
